import { useForm } from 'react-hook-form';

import Button from '../../Button';
import { TextField } from '../../Form';
import { Box, Flex } from '../../Grid';
import OnboardingLayout from '../OnboardingLayout';

import CardApply from './CardApply';
import CustomerService from './CustomerService';

const CardPrompt = ({ state, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register('cardNo', {
    required: 'Please enter a card number to continue',
    pattern: {
      value: /^[0-9]{16}$/,
      message: 'Please enter a valid card number to continue',
    },
  });
  function onSubmit(values) {
    dispatch({
      type: 'UPDATE_PAYLOAD',
      value: values,
      remove: 'resendOTP',
    });
    dispatch({
      type: 'PUSH_VIEW_HISTORY',
      value: CardPrompt,
    });
    dispatch({
      type: 'UPDATE_ENDPOINT',
      value: '/v1/enrollViaCard',
    });
  }
  return (
    <OnboardingLayout>
      <header className="onboard-header">
        <OnboardingLayout.Back dispatch={dispatch} />
        <h1 className="h2 onboard-title">
          To help us identify you better, please enter your 16-digit card number.
        </h1>
        <Flex alignItems="flex-start">
          <Box maxWidth={['none', '100%']} flexBasis={['100%']}>
            <img src="static/img/cards/register-link-card.png" alt="16 digits on front of card" />
          </Box>
        </Flex>
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="gtm-onboarding" id="card-prompt-number">
        <fieldset disabled={state.endpoint}>
          <TextField
            label="16-digit card number"
            errors={errors}
            type="text"
            name="cardNo"
            maxLength="16"
            {...rest}
            innerRef={ref}
          />
          <Button block type="submit">
            Next
          </Button>
        </fieldset>
      </form>
      <hr
        css={`
          margin-top: 3.75rem;
        `}
      />
      <Flex
        flexDirection={['column', 'row']}
        onClick={() =>
          dispatch({
            type: 'PUSH_VIEW_HISTORY',
            value: CardPrompt,
          })
        }
      >
        <Button
          secondary
          type="button"
          mr={['0', 'lg']}
          className="gtm-onboarding"
          id="card-prompt-no-card"
          onClick={() => dispatch({ type: 'CHANGE_VIEW', value: CardApply })}
        >
          I do not have a card
        </Button>
        <Button
          mt={['lg', '0']}
          secondary
          type="button"
          className="gtm-onboarding"
          id="card-prompt-lost-card"
          onClick={() => dispatch({ type: 'CHANGE_VIEW', value: CustomerService })}
        >
          I lost my card
        </Button>
      </Flex>
    </OnboardingLayout>
  );
};

export default CardPrompt;
