import React from 'react';

import { validateDOB } from '../../../../utils/dob';
import Button from '../../../Button';
import { TextField } from '../../../Form';
import OnboardingLayout from '../../OnboardingLayout';

import AddressField from './AddressField';
import CardDialog from './CardDialog';
import PromoCodeField from './PromoCodeField';
import TermsAndConditions from './TermsAndConditions';
import useCardApplyHook from './useCardApplyHook';

const CardApply = (props) => {
  const [state, dispatch] = useCardApplyHook(props);
  const { ref: fnameRef, ...fnameRest } = dispatch.registerField('fname', {
    required: 'Please enter your given name',
    pattern: {
      value: /^(?![0-9 ]*$)[a-zA-Z0-9-’' ]+$/,
      message: 'Please check your given name, it does not seem right.',
    },
  });
  const { ref: lnameRef, ...lnameRest } = dispatch.registerField('lname', {
    required: 'Please enter your surname',
    pattern: {
      value: /^(?![0-9 ]*$)[a-zA-Z0-9-’' ]+$/,
      message: 'Please check your surname, it does not seem right.',
    },
  });
  const { ref: dobRef, ...dobRest } = dispatch.registerField('dob', {
    validate: (value) => validateDOB(value) || true,
  });

  return (
    <OnboardingLayout>
      <header className="onboard-header">
        <OnboardingLayout.Back dispatch={dispatch.parentDispatch} />
        {state.flow === 'apply' ? (
          <h1 className="h2 onboard-title" data-testid="card-apply-component">
            Please enter your details below so we can mail out your Link card.
          </h1>
        ) : (
          <>
            <h1 className="h2 onboard-title">
              We will mail you a free Link card so you can fully enjoy a world of delights and
              rewards. Please enter your details below.
            </h1>
            <img width="210" height="132" alt="Link card" src="/static/img/cards/link-card.jpg" />
          </>
        )}
      </header>
      <form onSubmit={dispatch.handleFormSubmit} className="gtm-onboarding" id="card-apply">
        <fieldset disabled={state.endpoint}>
          <TextField
            data-testid="given-name-field"
            label="Given Name"
            errors={state.errors}
            name="fname"
            type="text"
            {...fnameRest}
            innerRef={fnameRef}
          />
          <TextField
            label="Surname"
            data-testid="sur-name-field"
            errors={state.errors}
            name="lname"
            type="text"
            {...lnameRest}
            innerRef={lnameRef}
          />
          <TextField
            dob
            data-testid="dob-field"
            label="Date of Birth"
            maxLength="14"
            errors={state.errors}
            name="dob"
            type="text"
            placeholder="DD/MM/YYYY"
            {...dobRest}
            innerRef={dobRef}
          />
          <AddressField
            addressText={state.addressText}
            errors={state.errors}
            dispatch={dispatch}
            showUnitNumberField={state.showUnitNumberField}
          />
          <PromoCodeField
            handlePromoCodeChange={dispatch.handlePromoCodeChange}
            promoCodeMessage={state.promoCodeMessage}
            errors={state.errors}
            promo={state.promo}
          />
          <TermsAndConditions register={dispatch.registerField} errors={state.errors} />
          <Button
            data-testid="submit-button"
            block
            type="submit"
            disabled={state.validatingPromoCode}
          >
            Submit
          </Button>
        </fieldset>
      </form>
      {state.modalVisible && (
        <CardDialog
          modalVisible={state.modalVisible}
          addressText={state.addressText}
          unitNumber={state.unitNumber}
          postalCode={state.postalCode}
          dispatch={dispatch}
        />
      )}
    </OnboardingLayout>
  );
};

export default CardApply;
