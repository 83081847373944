import { useForm } from 'react-hook-form';

import { validateDOB } from '../../../utils/dob';
import Button from '../../Button';
import { TextField } from '../../Form';
import OnboardingLayout from '../OnboardingLayout';

const SecurityDOB = ({ state, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref, ...rest } = register('securityAnswer', {
    validate: (value) => validateDOB(value) || true,
  });

  function onSubmit(values) {
    const cleaned = values.securityAnswer && values.securityAnswer.replace(/\s/g, '');
    dispatch({
      type: 'UPDATE_PAYLOAD',
      value: {
        securityAnswer: cleaned,
      },
    });
    dispatch({
      type: 'UPDATE_ENDPOINT',
      value: '/v1/verifyOnboardSecurity',
    });
  }

  return (
    <OnboardingLayout>
      <header className="onboard-header">
        <OnboardingLayout.Back dispatch={dispatch} />
        <h1 className="h2 onboard-title">
          Your contact details have changed. Please update it by entering your date of birth.
        </h1>
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="gtm-onboarding" id="security-dob">
        <fieldset disabled={state.endpoint}>
          <TextField
            dob
            label="Date of Birth"
            errors={errors}
            type="text"
            maxLength="14"
            name="securityAnswer"
            data-testid="securityAnswer"
            placeholder="DD/MM/YYYY"
            {...rest}
            innerRef={ref}
          />
          <Button block type="submit" data-testid="submit-button">
            Update
          </Button>
        </fieldset>
      </form>
    </OnboardingLayout>
  );
};

export default SecurityDOB;
