import React from 'react';

import { Checkbox, TextField } from '../../../Form';
import { Box } from '../../../Grid';

const TermsAndConditions = ({ register, errors }) => {
  const { ref: userConsentRef, ...userConsentRest } = register('userConsent');
  const { ref: userConsentDisplayedRef, ...userConsentDisplayedRest } =
    register('userConsentDisplayed');
  return (
    <Box mb="2.375rem">
      <Checkbox
        name="userConsent"
        id="user-consent"
        {...userConsentRest}
        innerRef={userConsentRef}
        label={
          <div>
            Send me Linkpoints and exclusive offers from FairPrice Group, its business partners and
            agents via email, text messages and phone calls.
            <p>
              By registering as a Link member, I agree to the
              <a
                href={`${process.env.NEXT_PUBLIC_WP_URL}/legal/terms-conditions`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {' '}
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a
                href={`${process.env.NEXT_PUBLIC_WP_URL}/legal/privacy-notice`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {' '}
                Privacy Notice
              </a>
              .
            </p>
          </div>
        }
      />
      <TextField
        errors={errors}
        name="userConsentDisplayed"
        type="hidden"
        value="true"
        {...userConsentDisplayedRest}
        innerRef={userConsentDisplayedRef}
      />
    </Box>
  );
};

export default TermsAndConditions;
