import React from 'react';
import styled from 'styled-components';

import { TextField } from '../../../Form';

const PromoCodeMessage = styled.small`
  display: block;
  margin-top: -2.375rem;
  margin-bottom: 2.375rem;
  color: ${(props) => props.theme.colors.purple};
  font-size: 0.875rem;
`;

const PromoCodeField = ({ handlePromoCodeChange, promoCodeMessage, errors, promo }) => (
  <>
    <TextField
      data-testid="promo-code-field"
      label="Promo code (if you have one)"
      errors={errors}
      name="promoCode"
      type="text"
      defaultValue={promo}
      onChange={handlePromoCodeChange}
      autoComplete="off"
    />
    {promoCodeMessage && (
      <PromoCodeMessage data-testid="promocode-msg">{promoCodeMessage}</PromoCodeMessage>
    )}
  </>
);

export default PromoCodeField;
