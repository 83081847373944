import { useForm } from 'react-hook-form';

import { removeSpaces, validateDOB } from '../../../utils/dob';
import Button from '../../Button';
import { Checkbox, TextField } from '../../Form';
import { Box } from '../../Grid';
import OnboardingLayout from '../OnboardingLayout';

const CardRegister = ({ state, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { ref: fnameRef, ...fnameRest } = register('fname', {
    required: 'Please enter your given name',
    pattern: {
      value: /^(?![0-9 ]*$)[a-zA-Z0-9-’' ]+$/,
      message: 'Please check your given name, it does not seem right.',
    },
  });
  const { ref: lnameRef, ...lnameRest } = register('lname', {
    required: 'Please enter your surname',
    pattern: {
      value: /^(?![0-9 ]*$)[a-zA-Z0-9-’' ]+$/,
      message: 'Please check your surname, it does not seem right.',
    },
  });
  const { ref: dobRef, ...dobRest } = register('dob', {
    validate: (value) => validateDOB(value) || true,
  });

  const { ref: userConsentRef, ...userConsentRest } = register('userConsent');
  const { ref: userConsentDisplayedRef, userConsentDisplayedRest } =
    register('userConsentDisplayed');

  function onSubmit(values) {
    const cleanedDOB = values.dob && removeSpaces(values.dob);
    dispatch({
      type: 'UPDATE_PAYLOAD',
      value: {
        ...values,
        dob: cleanedDOB,
      },
    });
    dispatch({
      type: 'UPDATE_ENDPOINT',
      value: '/v1/enrollViaPreIssuedCard',
    });
  }

  return (
    <OnboardingLayout>
      <header className="onboard-header">
        <OnboardingLayout.Back dispatch={dispatch} />
        <h1 className="h2 onboard-title">
          One last step to earning 300 Linkpoints! Tell us more about yourself.
        </h1>
      </header>

      <form onSubmit={handleSubmit(onSubmit)} className="gtm-onboarding" id="card-register">
        <fieldset disabled={state.endpoint}>
          <TextField
            label="Given Name"
            errors={errors}
            name="fname"
            data-testid="fname"
            type="text"
            {...fnameRest}
            innerRef={fnameRef}
          />
          <TextField
            label="Surname"
            errors={errors}
            name="lname"
            data-testid="lname"
            type="text"
            {...lnameRest}
            innerRef={lnameRef}
          />
          <TextField
            dob
            label="Date of Birth"
            maxLength="14"
            errors={errors}
            name="dob"
            data-testid="dob"
            type="text"
            placeholder="DD/MM/YYYY"
            {...dobRest}
            innerRef={dobRef}
          />
          <Box mb="2.375rem">
            <Checkbox
              name="userConsent"
              id="user-consent"
              {...userConsentRest}
              innerRef={userConsentRef}
              label={
                <span>
                  Send me exclusive Link member and related partners' offers and updates via email,
                  text messages, phone calls.
                  <p>
                    By registering as a Link member, I agree to the
                    <a
                      href={`${process.env.NEXT_PUBLIC_WP_URL}/legal/terms-conditions`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {' '}
                      Terms & Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href={`${process.env.NEXT_PUBLIC_WP_URL}/legal/privacy-notice`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {' '}
                      Privacy Policy
                    </a>
                    .
                  </p>
                </span>
              }
            />
            <TextField
              errors={errors}
              name="userConsentDisplayed"
              data-testid="userConsentDisplayed"
              {...userConsentDisplayedRest}
              innerRef={userConsentDisplayedRef}
              type="hidden"
              value="true"
            />
          </Box>

          <Button block type="submit" data-testid="submit-button">
            Next
          </Button>
        </fieldset>
      </form>
    </OnboardingLayout>
  );
};

export default CardRegister;
