import { CUSTOMER_SERVICE_NUMBER } from '../../../constants';
import redirectToPartner from '../../../utils/redirectToPartner';
import Button from '../../Button';
import { Contain, Flex } from '../../Grid';

const CustomerService = ({ code, incorrect, message, url }) => {
  const redirectUrl = url ? redirectToPartner(url, false) : null;
  return (
    <Contain
      className="text-center"
      css={`
        margin-top: 3rem;
      `}
    >
      <img alt="Unable to log you in" src="/static/img/login-error.png" />
      <h1 className="h2 onboard-title">We’re unable to get you logged in</h1>

      {code === '0010' && (
        <p>
          You've entered the incorrect OTP too many times. Please try again in 1 hour.
          <br />
        </p>
      )}
      {code !== '0010' && (
        <p>
          {incorrect && (
            <>
              You've entered the incorrect {incorrect} too many times.
              <br />
            </>
          )}
          {message && (
            <>
              {message}
              <br />
            </>
          )}
          Please contact our friendly customer service team for assistance.
        </p>
      )}
      {redirectUrl && (
        <>
          <p>Otherwise, you can proceed to our partner's site without Linkpoints issuance</p>
          <Button mb="xl" secondary href={redirectUrl}>
            Proceed to Partner's site
          </Button>
          <br />
        </>
      )}
      <Flex justifyContent="center" flexDirection={['column', 'row']}>
        <Button mr={['0', 'lg']} secondary href="/contact-us" prefetch={false}>
          Email
        </Button>
        <Button mt={['lg', '0']} white href={`tel:${CUSTOMER_SERVICE_NUMBER.replace(/\s/, '')}`}>
          Call {CUSTOMER_SERVICE_NUMBER}
        </Button>
      </Flex>
    </Contain>
  );
};

export default CustomerService;
