import { useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { removeSpaces } from '../../../../utils/dob';
import { post } from '../../../../utils/fetch';
import useAddress from '../../../../utils/useAddress';

const reducer = (state, action) => {
  switch (action.type) {
    case 'MODAL_VISIBILITY':
      return { ...state, modalVisible: action.value };
    case 'FORM_VALUES':
      return { ...state, formVals: action.value };
    case 'PROMOCODE_MSG':
      return { ...state, promoCodeMessage: action.value };
    case 'TOGGLE_VALIDATING_PROMO_CODE':
      return {
        ...state,
        validatingPromoCode: !state.validatingPromoCode,
      };
    case 'ON_PROMOCODE_CHANGED':
      return {
        ...state,
        promoCodeMessage: action.promoCodeMessage,
        validatingPromoCode: !state.validatingPromoCode,
      };
    case 'ON_CONFIRM_FORM_SUBMIT':
      return {
        ...state,
        modalVisible: action.modalVisible,
        unitNumber: action.unitNumber,
        postalCode: action.postalCode,
        formVals: action.formVals,
      };
    case 'HANDLE_CONFIRM_ADDRESS':
      return {
        ...state,
        formVals: action.formVals,
        modalVisible: action.modalVisible,
      };
    default:
      return state;
  }
};

const useCardApplyHook = ({ state: parentState, dispatch: parentDispatch }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { addressText, showUnitNumberField, handlePostalCodeChange, handleUnitNumberChange } =
    useAddress({
      setValue,
      getValues,
      setError,
      clearErrors,
    });

  register('address', { required: true });
  register('promoCode');

  const initialState = {
    modalVisible: false,
    formVals: null,
    promoCodeMessage: null,
    validatingPromoCode: false,
    unitNo: null,
    postalCode: null,
    ...parentState,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const debouncedValidate = debounce(async (val) => {
    const res = await post('/v1/validatePromoCode', {
      body: {
        body: {
          promoCode: val,
          mobileNumber: state.payload.mobileNumber,
        },
      },
    });

    if (res) {
      dispatch({ type: 'TOGGLE_VALIDATING_PROMO_CODE' });
    }

    if (res.code === '1000') {
      setValue('promoCode', null);
      setError('promoCode', 'validation', 'The promo code you entered is invalid');
      return false;
    }
    clearErrors('promoCode');
    dispatch({ type: 'PROMOCODE_MSG', value: res.response.awardMessage });
    setValue('promoCode', val);
    return true;
  }, 500);

  const mapDispatch = {
    confirmFormSubmit: (values) => {
      if (state.validatingPromoCode) {
        return;
      } // do nothing if validation in progress

      const cleanedDOB = state.formVals.dob && removeSpaces(state.formVals.dob);

      parentDispatch({
        type: 'UPDATE_PAYLOAD',
        value: {
          ...state.formVals,
          dob: cleanedDOB,
        },
      });

      parentDispatch({
        type: 'UPDATE_ENDPOINT',
        value: '/v2/enrollViaNoCard',
      });

      dispatch({
        type: 'ON_CONFIRM_FORM_SUBMIT',
        modalVisible: false,
        unitNumber: values.unitNumber,
        postalCode: values.postalCode,
        formVals: values,
      });
    },
    handleFormSubmit: handleSubmit((values) => {
      dispatch({
        type: 'ON_CONFIRM_FORM_SUBMIT',
        modalVisible: true,
        unitNumber: values.unitNumber,
        postalCode: values.postalCode,
        formVals: values,
      });
    }),
    handlePromoCodeChange: async (e) => {
      const val = e.target.value.trim();
      dispatch({
        type: 'ON_PROMOCODE_CHANGED',
        promoCodeMessage: null,
      });

      if (val.length === 0) {
        clearErrors('promoCode');
        dispatch({ type: 'TOGGLE_VALIDATING_PROMO_CODE' });
        setValue('promoCode', null);
        return;
      }

      debouncedValidate(val);
    },
    registerField: register,
    handlePostalCodeChange,
    handleUnitNumberChange,
    toggleModal: (value) => dispatch({ type: 'MODAL_VISIBILITY', value }),
    parentDispatch,
  };

  useEffect(() => {
    if (state.promo) {
      dispatch({ type: 'TOGGLE_VALIDATING_PROMO_CODE' });
      debouncedValidate(state.promo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [{ ...state, errors, showUnitNumberField, addressText }, mapDispatch];
};

export default useCardApplyHook;
