import CardApply from './CardApply';
import CardPrompt from './CardPrompt';
import CardRegister from './CardRegister';
import CustomerService from './CustomerService';
import EmailPrompt from './EmailPrompt';
import MobileLanding from './MobileLanding';
import MobileLandingApply from './MobileLandingApply';
import MobileLandingRegister from './MobileLandingRegister';
import MobileUpdate from './MobileUpdate';
import MobileVerifyOTP from './MobileVerifyOTP';
import SecurityDOB from './SecurityDOB';
import SecurityEmail from './SecurityEmail';
import SecurityMobile from './SecurityMobile';

export {
  MobileLanding,
  MobileLandingApply,
  MobileLandingRegister,
  MobileVerifyOTP,
  MobileUpdate,
  EmailPrompt,
  CardPrompt,
  CardRegister,
  CardApply,
  CustomerService,
  SecurityDOB,
  SecurityMobile,
  SecurityEmail,
};
