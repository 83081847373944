import React from 'react';
import styled from 'styled-components';

import { TextField } from '../../../Form';

const Hint = styled.p`
  font-size: 0.875rem;
  font-style: italic;
`;

const regex = new RegExp(
  [
    '^[0-9]{2}[-][0-9]{2,5}$|^[0-9]{2}[-][0-9]{2,4}[a-zA-Z]{1}$',
    '|^[0-9]{2}[-][0-9]{2,3}[a-zA-Z]{1}[0-9]{1}$',
    '|^[0-9]{2}[-][0-9]{2}[a-zA-Z]{1}[0-9]{0,2}$',
    '|^[0-9]{2}[-][0-9]{1}[a-zA-Z]{1}[0-9]{0,3}$',
    '|^[0-9]{2}[-][a-zA-Z]{1}[0-9]{1,4}$',
  ].join(''),
);

const AddressField = ({ addressText, errors, dispatch, showUnitNumberField }) => {
  const {
    ref: postalCodeRef,
    onChange: postalCodeOnChange,
    ...postalCodeRest
  } = dispatch.registerField('postalCode', {
    required: 'Please enter a postal code',
  });
  const {
    ref: unitNumRef,
    onChange: unitNumOnChange,
    ...unitNumRest
  } = showUnitNumberField &&
  dispatch.registerField('unitNumber', {
    required: 'Please enter your unit number',
    pattern: {
      value: regex,
      message: "Your unit number doesn't seem right. Try again.",
    },
  });
  return (
    <>
      <TextField
        minLength="6"
        maxLength="6"
        label="Postal code"
        errors={errors}
        type="text"
        name="postalCode"
        {...postalCodeRest}
        innerRef={postalCodeRef}
        onChange={(e) => {
          postalCodeOnChange(e);
          dispatch.handlePostalCodeChange(e);
        }}
      >
        {addressText && <p>{addressText}</p>}
      </TextField>

      {showUnitNumberField && (
        <>
          <TextField
            data-testid="unit-number-field"
            name="unitNumber"
            label={
              <>
                <div>
                  Please enter your unit number so we can mail your <strong>free Link card</strong>.
                  <Hint>If you live in a landed home, please enter 00-00</Hint>
                </div>
              </>
            }
            maxLength="8"
            errors={errors}
            type="text"
            placeholder="01-23"
            {...unitNumRest}
            innerRef={unitNumRef}
            onChange={(e) => {
              unitNumOnChange(e);
              dispatch.handleUnitNumberChange(e);
            }}
          />
        </>
      )}
    </>
  );
};

export default AddressField;
