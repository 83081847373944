import React from 'react';

import AlertDialog from '../../../AlertDialog';
import Button from '../../../Button';
import { Flex } from '../../../Grid';

const CardDialog = ({ modalVisible, addressText, unitNumber, postalCode, dispatch }) => (
  <AlertDialog
    ariaLabel="Formfield"
    isOpen={modalVisible}
    onDismiss={() => dispatch.toggleModal(false)}
  >
    <fieldset className="text-center" data-testid="confirmation-dialog">
      <h2>Verify your mailing address</h2>
      <p>{addressText}</p>
      <p># {unitNumber}</p>
      <p>Singapore {postalCode}</p>
      <p>
        <em>Is this address correct?</em>
      </p>
      <Flex justifyContent="center" pb="md">
        <Button onClick={() => dispatch.toggleModal(false)} secondary type="button" mr="lg">
          Change
        </Button>
        <Button onClick={dispatch.confirmFormSubmit} type="button">
          Yes
        </Button>
      </Flex>
    </fieldset>
  </AlertDialog>
);

export default CardDialog;
