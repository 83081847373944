import { headers, post } from './fetch';

function fetchAddress(postalCode) {
  return post('/v2/getAddress', {
    headers: headers(),
    body: {
      body: { postalCode },
    },
  });
}
export default fetchAddress;
