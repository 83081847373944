import { useCallback, useEffect, useReducer } from 'react';
import snakeCase from 'lodash/snakeCase';

import fetchAddress from './fetchAddress';

const initialState = {
  addressText: null,
  showUnitNumberField: false,
  oldUnitNo: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ON_CORRECT_POSTAL_CODE':
      return {
        ...state,
        addressText: action.value,
        showUnitNumberField: true,
        oldUnitNo: action.oldUnitNo,
      };
    case 'ON_INCORRECT_POSTAL_CODE':
      return { ...state, addressText: null, showUnitNumberField: false };
    case 'SHOW_UNIT_NO':
      return { ...state, showUnitNumberField: false };
    case 'SET_OLD_UNITNO':
      return { ...state, oldUnitNo: action.value };
    default:
      return state;
  }
};

const useAddress = ({
  setValue,
  getValues,
  setError,
  clearErrors,
  initialPostalCode,
  initialUnitNumber,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMatchUnitNo = (str = '') => {
    let floor = '';
    let unit = '';
    const matchedUnitNumber = str.trim().match(/^([0-9]{2}$)|^[0-9]{2}[-][0-9]{1,4}[a-zA-Z0-9]$/);
    if (matchedUnitNumber) {
      const unitString = matchedUnitNumber[0];
      [floor, unit = ''] = unitString.split('-');
    }
    return { floor, unit, matchedUnitNumber };
  };

  const processUnitNumber = (str = '') => {
    const addressData = getValues().address;
    const { floor, unit, matchedUnitNumber } = isMatchUnitNo(str);
    setValue('address', {
      ...addressData,
      building_floor: floor,
      building_unit: unit,
    });

    dispatch({ type: 'SET_OLD_UNITNO', value: str });

    if (
      str.length === 2 &&
      state.oldUnitNo &&
      state.oldUnitNo.length <= str.length &&
      matchedUnitNumber
    ) {
      return str.concat('-');
    }
    return str;
  };

  const doFetch = useCallback(
    async (postalCode) => {
      const res = await fetchAddress(postalCode);
      if (res.code === '0000') {
        const {
          response: {
            postalCodeData,
            chatBootresponse: {
              chatServerMsg: [{ displayMessage }],
            },
          },
        } = res;
        const retrievedAddress = Object.entries(postalCodeData).map((arr) => [
          snakeCase(arr[0]),
          arr[1],
        ]);
        const reformattedAddress = Object.fromEntries(retrievedAddress);
        const addressData = getValues().address;
        const { floor, unit } = isMatchUnitNo(initialUnitNumber);

        setValue('address', {
          ...addressData,
          ...reformattedAddress,
          building_floor: floor,
          building_unit: unit,
        });

        const extractedAddress = displayMessage.match(/^Your address is (.*).$/)[1];
        dispatch({
          type: 'ON_CORRECT_POSTAL_CODE',
          value: extractedAddress,
          oldUnitNo: initialUnitNumber,
        });
        clearErrors('postalCode');
      } else {
        setError(
          'postalCode',
          'validation',
          'Oops, enter a valid postal code so we can detect your location!',
        );
        dispatch({
          type: 'SHOW_UNIT_NO',
          value: false,
          oldUnitNo: initialUnitNumber,
        });
      }
    },
    [clearErrors, getValues, initialUnitNumber, setError, setValue],
  );

  function handlePostalCodeChange(e) {
    const postalCode = e.target.value;
    if (postalCode.length < 6) {
      dispatch({ type: 'ON_INCORRECT_POSTAL_CODE' });
      return;
    }
    doFetch(postalCode);
  }

  function handleUnitNumberChange(e) {
    const val = processUnitNumber(e.target.value);
    e.target.value = val;
  }

  useEffect(() => {
    if (initialPostalCode) {
      doFetch(initialPostalCode);
    }
  }, [initialPostalCode, doFetch]);

  return {
    addressText: state.addressText,
    showUnitNumberField: state.showUnitNumberField,
    handlePostalCodeChange,
    handleUnitNumberChange,
  };
};
export default useAddress;
