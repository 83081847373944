export const validateDOB = (val) => {
  const dob = val.trim().replace(/\s/g, '');

  if (!dob) {
    return 'Please enter your date of birth';
  }

  if (/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(dob)) {
    const valid = isValidDate(dob);

    if (!valid) {
      return 'Please enter a valid date of birth';
    }

    return ''; // Validation passed
  }

  return 'Please enter a date of birth in the format DD/MM/YYYY';
};

export const isValidDate = (dob) => {
  const [dobDay, dobMonth, dobYear] = dob.split('/');
  const currYear = new Date().getFullYear();
  const monthsHave30Days = ['04', '06', '09', '11'];

  if (dobYear >= currYear || dobYear < 1900) {
    return false;
  } else if (monthsHave30Days.includes(dobMonth) && dobDay === '31') {
    return false;
  } else if (dobMonth === '02') {
    const isLeap = (dobYear % 4 === 0 && dobYear % 100 !== 0) || dobYear % 400 === 0;
    if (dobDay > 29 || (dobDay === '29' && !isLeap)) {
      return false;
    }
  }
  return true;
};

export const addSlashes = (dob) => {
  return (dob && `${dob.slice(0, 2)}/${dob.slice(2, 4)}/${dob.slice(4, 8)}`) || '-';
};

export const removeSlashes = (dob) => {
  return dob.replace(/\s|\//g, ''); // convert "01 / 01 / 2019" => "01012019"
};

export const removeSpaces = (dob) => {
  return dob.replace(/\s/g, '');
};
